import React, {useEffect, useMemo, useState} from 'react';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';
import { SaveOutlined, UserAddOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined} from '@ant-design/icons';
import { AssignStudentsActions, CourseActions, UtilsActions } from '../../../app/redux/actions';
import { AssignStudentsSelectors, CourseSelectors } from '../../../app/redux/reducers';
import {
  Avatar,
  Breadcrumb,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Row,
  Tooltip
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { UserPlanOptionsArray } from '../../../app/enum/userPlanOption';
import { getPhoneNumber, maskPhone } from '../../../app/utils/masks';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import { simpleSearchOnArray } from '../../../app/services/filter';
import { getInitials, removeSpecialCharsStr } from '../../../app/utils/string';
import { returnPlanName } from '../../../app/utils/returnPlanName';
import { ControlledForm } from '../../../components/shared/ControlledForm/ControlledForm';

const { Panel } = Collapse;
const { Content } = Layout;

function AssignStudents (props) {

  const [ checkedList, setCheckedList ] = useState([]);
  const [ checkAll, setCheckAll ] = useState(false);
  const [ course, setCourse ] = useState();
  const [ courseList, setCourseList ] = useState(props.courseList);
  const [ company, setCompany ] = useState();
  const [ companyList, setCompanyList ] = useState([]);
  const [ filters, setFilters ] = useState({})

  const allUsersArray = useMemo(() => {
    return props.allStudents.map((item) => item.id)
  },[ props.allStudents ])

  useEffect(() => {
    props.getAllStudents()
    props.getCourses();
    getCompanyList();
  },[]);

  useEffect(() => {
    props.getSelectedStudents({quizId: props.id, courses: course?.join(',')})
  }, [ course ])

  useEffect(() => {
    onChange(props.initialStudents?.map((item) => item.id) || [])
  }, [ props.initialStudents ]);

  const getCompanyList = async () => {
    const { getCompanies } = props;
    getCompanies((data) => {
      setCompanyList(data);
    });
  }

  const getCourseByCompany = async (value) => {
    const { getCourseByCompany } = props;
    getCourseByCompany(value, (data) => {
      setCourseList(data);
    });
  };

  const returnSpecialization = (specialization) => {
    if (!specialization || specialization.length === 0) return '--'

    return specialization.map((i) => i.title).join(', ')
  }

  const onSelectCompany = async (val) => {
    setCompany(val);
    const courseList = await getCourseByCompany(val);
    setCourse(null);
    setCourseList(courseList);
  }

  const onSelectCourse = async (val) => {
    setCourse(val);

    if (!val || !val.length) {
      setCheckAll(false);
      return props.getAllStudents();
    }

    const students = await props.getStudentsByQuiz({ courseIds: val.join()})
    props.getAllStudents(students)
  }

  const applyByCourse = async () => {
    if(course && !course.length) return

    const students = await props.getStudentsByQuiz({ courseIds: course && course.join()})
    const itemsToCheck = students.map((item) => {
      if(!checkedList.includes(item.id)) return item.id
    })
    setCheckedList([ ...checkedList, ...itemsToCheck ])
  }

  const removeByCourse = async () => {
    if (course && !course.length) return;

    let students = await props.getStudentsByQuiz({ courseIds: course.join()})
    students = students.map((item) => item.id)
    const removedStudents = checkedList.filter((item) => {
      if(!students.includes(item)) return item
    })
    setCheckedList(removedStudents)
  }

  const checkAllStudents = (e) => {
    setCheckAll(e)

    if (e) return setCheckedList(allUsersArray)

    setCheckedList([])
  }

  const filter = () => {
    return simpleSearchOnArray(props.allStudents, {
      ...filters,
      cellphone: removeSpecialCharsStr(filters.cellphone)
    })
  }

  const checkByFilter = async () => {
    if(filter().length === 0 || filter().length === props.allStudents.length) return

    const students = filter()
    const itemsToCheck = students.map((item) => {
      if(!checkedList.includes(item.id)) return item.id
    })
    setCheckedList([ ...checkedList, ...itemsToCheck ])
  }

  const uncheckByFilter = async () => {
    if(filter().length === 0 || filter().length === props.allStudents.length) return

    let students = filter()
    students = students.map((item) => item.id)
    const removedStudents = checkedList.filter((item) => {
      if(!students.includes(item)) return item
    })
    setCheckedList(removedStudents)
  }

  const onChange = (list) => {
    setCheckedList(list);
  };

  const onSubmit = () => {
    const assignPayload = {
      quizId: props.id,
      usersIdsList: checkedList,
      coursesIdsList: course,
    }

    if(course && !course.length) {
      return message.error(I18n.t('routes.panel.assignStudents.selectCourse'))
    }

    try {
      props.updateSelectedStudents(assignPayload)
    } catch (err) {
      message.error(err);
    } finally {
      navigate(I18n.t('routes.panel.quiz.urlQuiz'));
    }

  }

  const viewSelectedStudents = () => {
    const checkedToView = props.allStudents.filter((item) => {
      if(checkedList.includes(item.id)) return item.id
    })
    props.getAllStudents(checkedToView)
  }

  const dataToShow = useMemo(filter, [ props.allStudents, filters ])

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${I18n.t('routes.panel.quiz.urlQuiz')}`}>
              <span>{I18n.t('routes.panel.quiz.pageQuiz')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${I18n.t('routes.panel.quiz.urlQuizAddUsers')}${props.id}`}>
              <span>{I18n.t('routes.panel.quiz.AssignStudents')}</span>
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <Content className="panel__layout__content panel__layout__content--advanced-filter">
        <Collapse
          className="advanced-filter"
        >
          <Panel
            header={I18n.t('shared.advancedFilters.title')}
            key="1"
          >
            <ControlledForm
              onSubmit={setFilters}
              onReset={() => setFilters({})}
              formRenderer={({onFieldChange, submit, reset, values}) => (
                <Col>
                  <Row gutter={16}>
                    <Col span={8}>
                      <AdvancedInput
                        placeholder={I18n.t('shared.type')}
                        label={I18n.t('shared.advancedFilters.form.name')}
                        onChange={(val) => onFieldChange('name', val)}
                        value={values.name}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedInput
                        placeholder={I18n.t('shared.type')}
                        label={I18n.t('shared.advancedFilters.form.email')}
                        onChange={(val) => onFieldChange('email', val)}
                        value={values.email}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedInput
                        placeholder={I18n.t('shared.type')}
                        label={I18n.t('shared.advancedFilters.form.telephone')}
                        onChange={(val) => onFieldChange('cellphone', maskPhone(val))}
                        value={values.cellphone}
                      />
                    </Col>
                    <Col span={6}>
                      <AdvancedSelect
                        options={UserPlanOptionsArray}
                        label={I18n.t('shared.advancedFilters.form.plan')}
                        onChange={(val) => onFieldChange('planOption', val)}
                        value={values.planOption}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedInput
                        placeholder={I18n.t('shared.type')}
                        label={I18n.t('shared.advancedFilters.form.cityLabel')}
                        onChange={(val) => onFieldChange('city', val)}
                        value={values.city}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col
                      span={24}
                      className="advanced-filter__search-button text-right"
                    >
                      <AdvancedButton
                        text={I18n.t('shared.advancedFilters.clearButtonText')}
                        onClick={reset}
                        htmlType='reset'
                      />
                      <Divider type="vertical" />
                      <AdvancedButton
                        text={I18n.t('shared.advancedFilters.filterButtonText')}
                        onClick={submit}
                        htmlType='submit'
                      />
                      <Divider type="vertical" />
                      <AdvancedButton
                        text={I18n.t('routes.panel.assignStudents.sectionAddOrRemoveStudentsByCourses.buttons.select')}
                        onClick={() => checkByFilter()}
                      />
                      <Divider type="vertical" />
                      <AdvancedButton
                        text={I18n.t('routes.panel.assignStudents.sectionAddOrRemoveStudentsByCourses.buttons.remove')}
                        onClick={() => uncheckByFilter()}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            />
          </Panel>
        </Collapse>
      </Content>
      <Content className="panel__layout__content panel__layout__content--advanced-filter">
        <Collapse
          className="advanced-filter"
        >
          <Panel
            header={I18n.t('routes.panel.assignStudents.sectionAddOrRemoveStudentsByCourses.title')}
            key="1"
          >
            <Row>
              <Col span={9}>
                <Col>
                  <h6>{I18n.t('routes.panel.assignStudents.sectionAddOrRemoveStudentsByCourses.filters.company')}</h6>
                </Col>
                <Row>
                  <Col span={14}>
                    <AdvancedSelect
                      options={companyList}
                      value={company}
                      onChange={(val) => onSelectCompany(val)}
                      onSelect={(val) => getCourseByCompany(val)}
                      disableSearch
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={9}>
                <Col>
                  <h6>{I18n.t('routes.panel.assignStudents.sectionAddOrRemoveStudentsByCourses.filters.courses')}</h6>
                </Col>
                <Row>
                  <Col span={14}>
                    <AdvancedSelect
                      mode="multiple"
                      value={course}
                      onChange={(val) => onSelectCourse(val)}
                      options={courseList}
                      disabled={!company}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <Col>
                  <br />
                </Col>
                <Row>
                  <Col align='right'>
                    <AdvancedButton
                      text={I18n.t('routes.panel.assignStudents.sectionAddOrRemoveStudentsByCourses.buttons.add')}
                      icon={<UsergroupAddOutlined />}
                      onClick={() => applyByCourse()}
                    />
                    <Divider type="vertical" />
                    <AdvancedButton
                      text={I18n.t('routes.panel.assignStudents.sectionAddOrRemoveStudentsByCourses.buttons.remove')}
                      icon={<UsergroupDeleteOutlined />}
                      onClick={() => removeByCourse()}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Content>
      <Content className="panel__layout__content">
        <div className="users">
          <Row>
            <Col span={12}>
              <h3>
                <span className="panel__layout__content__title__value__icon">
                  <UserAddOutlined />
                </span>
                {I18n.t('routes.panel.quiz.AssignStudents')}
              </h3>
            </Col>
            <Col span={12}
              align='right'>
              <AdvancedButton
                text={I18n.t('routes.panel.assignStudents.buttons.viewSelected')}
                onClick={() => viewSelectedStudents()}
              />
              <Divider type="vertical" />
              <AdvancedButton
                type="link"
                href={`${I18n.t('routes.panel.quiz.urlQuiz')}`}
                text={I18n.t('forms.goBackButtonText')}
              />
              <Divider type="vertical" />
              <AdvancedButton
                text={I18n.t('routes.panel.assignStudents.buttons.conclude')}
                icon={<SaveOutlined />}
                onClick={() => onSubmit()}
                disabled={!course}
              />
            </Col>
          </Row>
          <Row>
            <div className="assign-students__table assign-students__table--header">
              <div className="assign-students__table__checkbox">
                <Checkbox
                  checked={checkAll}
                  onChange={(e) => checkAllStudents(e.target.checked)}
                  disabled={!company}
                >
                  &nbsp;
                </Checkbox>
              </div>
              <Divider type='vertical' />
              <div className="assign-students__table__avatar">
              </div>
              <Divider type='vertical' />
              <div className="assign-students__table__name">
                {I18n.t('routes.panel.assignStudents.dataTable.columns.name.title')}
              </div>
              <Divider type='vertical' />
              <div className="assign-students__table__email">
                {I18n.t('routes.panel.assignStudents.dataTable.columns.email.title')}
              </div>
              <Divider type='vertical' />
              <div className="assign-students__table__phone">
                {I18n.t('routes.panel.assignStudents.dataTable.columns.telephone.title')}
              </div>
              <Divider type='vertical' />
              <div className="assign-students__table__specialization">
                {I18n.t('routes.panel.assignStudents.dataTable.columns.specializations.title')}
              </div>
              <Divider type='vertical' />
              <div className="assign-students__table__plan">
                {I18n.t('routes.panel.assignStudents.dataTable.columns.plan.title')}
              </div>
              <Divider type='vertical' />
              <div className="assign-students__table__city">
                {I18n.t('routes.panel.assignStudents.dataTable.columns.city.title')}
              </div>
            </div>
          </Row>
          <Divider />
          <Row>
            <Checkbox.Group
              value={checkedList}
              onChange={(e) => onChange(e)}
            >
              <Row>
                {dataToShow.map((item) => (
                  <>
                    <Col
                      key={item.id}
                      span={24}
                    >
                      <div className="assign-students__table">
                        <div className="assign-students__table__checkbox">
                          {!company ? (
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.assignStudents.selectCompany',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Checkbox
                                value={item.id}
                                disabled={!company}
                              > &nbsp;
                              </Checkbox>
                            </Tooltip>
                          ) : (
                            <Checkbox
                              value={item.id}
                            > &nbsp;
                            </Checkbox>
                          )}

                        </div>
                        <Divider type='vertical' />
                        <div className="assign-students__table__avatar">
                          {item.photoUrl ?
                            <Avatar src={item.photoUrl} />
                            :
                            <Avatar>
                              {getInitials(item.name)}
                            </Avatar>
                          }
                        </div>
                        <Divider type='vertical' />
                        <div className="assign-students__table__name">
                          {item.name || '--'}
                        </div>
                        <Divider type='vertical' />
                        <div className="assign-students__table__email">
                          {item.email || '--'}
                        </div>
                        <Divider type='vertical' />
                        <div className="assign-students__table__phone">
                          {item.cellphone && item.cellphone !== 'null' ? getPhoneNumber(item.cellphone) : '--'}
                        </div>
                        <Divider type='vertical' />
                        <div className="assign-students__table__specialization">
                          {returnSpecialization(item.specializations)}
                        </div>
                        <Divider type='vertical' />
                        <div className="assign-students__table__plan">
                          {returnPlanName(item.planOption)}
                        </div>
                        <Divider type='vertical' />
                        <div className="assign-students__table__city">
                          {item.city || '--'}
                        </div>
                      </div>
                    </Col>
                    <Divider />
                  </>
                ))}
              </Row>
            </Checkbox.Group>
          </Row>
        </div>
        <Col span={24}
          align='right'>
          <AdvancedButton
            type="link"
            href={`${I18n.t('routes.panel.quiz.urlQuiz')}`}
            text={I18n.t('forms.goBackButtonText')}
          />
          <Divider type="vertical" />
          <AdvancedButton
            text={I18n.t('shared.save')}
            icon={<SaveOutlined />}
            onClick={() => onSubmit()}
            disabled={!course}
          />
        </Col>
      </Content>
    </>
  )
}

const mapStateToProps = (state) => ({
  allStudents: AssignStudentsSelectors.getAllStudents(state),
  courseList: CourseSelectors.getCourseSelectives(state),
  studentsByQuiz: AssignStudentsSelectors.getStudentsByQuiz(state),
  initialStudents: AssignStudentsSelectors.getSelectedStudents(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllStudents: (students) => dispatch(AssignStudentsActions.getAllStudents(students)),
  getCourses: (params) => dispatch(CourseActions.getCourseSelectivesQuiz(params)),
  getStudentsByQuiz: (params) => dispatch(AssignStudentsActions.getStudentsByQuiz(params)),
  getSelectedStudents: (params) => dispatch(AssignStudentsActions.getSelectedStudents(params)),
  updateSelectedStudents: (params) => dispatch(AssignStudentsActions.updateSelectedStudents(params)),
  getCourseByCompany: (params, callback) => dispatch(UtilsActions.getCourseByCompany(params, callback)),
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  AssignStudents,
);
